import React from 'react';
import { Link } from 'react-router-dom';
import {
  ButtonTextContainer,
  Label,
  LeftIconContainer,
  RightIconContainer,
} from './ButtonText.style';

const ButtonText = ({
  buttonContent,
  leftIcon,
  rightIcon,
  onClick,
  disabled = false,
  className,
  type = 'button',
  ariaLabel = 'text button',
  to,
}: {
  buttonContent: string | JSX.Element;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  onClick?: (e: any) => void;
  disabled?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  ariaLabel?: string;
  to?: string;
}): JSX.Element => {
  const children = (
    <>
      {leftIcon !== undefined && <LeftIconContainer>{leftIcon}</LeftIconContainer>}
      {typeof buttonContent === 'string' ? <Label>{buttonContent}</Label> : buttonContent}
      {rightIcon !== undefined && <RightIconContainer>{rightIcon}</RightIconContainer>}
    </>
  );

  if (to !== undefined) {
    return (
      <ButtonTextContainer
        className={className}
        aria-label={ariaLabel}
        disabled={disabled}
        as={Link}
        to={to}
      >
        {children}
      </ButtonTextContainer>
    );
  }
  return (
    <ButtonTextContainer
      className={className}
      aria-label={ariaLabel}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {children}
    </ButtonTextContainer>
  );
};

export default ButtonText;
